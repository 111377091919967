import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'logo_vk_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="logo_vk_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.546 4.546C3 6.093 3 8.582 3 13.56v.88c0 4.978 0 7.467 1.546 9.013C6.093 25 8.582 25 13.56 25h.88c4.978 0 7.467 0 9.013-1.547C25 21.907 25 19.418 25 14.44v-.88c0-4.978 0-7.467-1.547-9.014C21.907 3 19.418 3 14.44 3h-.88C8.582 3 6.093 3 4.546 4.546zM6.7 9.7c.12 5.747 2.984 9.2 8.006 9.2h.285v-3.288c1.846.184 3.241 1.538 3.801 3.288H21.4c-.716-2.615-2.598-4.061-3.774-4.614 1.175-.681 2.828-2.339 3.223-4.586H18.48c-.514 1.823-2.038 3.481-3.489 3.638V9.7h-2.369v6.373c-1.469-.369-3.324-2.155-3.406-6.373H6.7z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28LogoVkProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28LogoVk: FC<Icon28LogoVkProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28LogoVk as any).mountIcon = mountIcon;

export default Icon28LogoVk;
